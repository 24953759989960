import React, { useId } from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

const AiIcon: React.FC<SvgIconProps> = (props) => {
  const id = useId();
  return (
    <SvgIcon width="32" height="33" viewBox="0 0 32 33" {...props}>
      <defs>
        <linearGradient id={`${id}-gradient`} x2="100%">
          <stop stop-color="#31d4b6" />
          <stop offset="1" stop-color="#48ff9b" />
        </linearGradient>
      </defs>
      <path
        d="M22.7079 19.5616C22.7079 19.852 22.6084 20.0884 22.4092 20.2709C22.2184 20.4534 21.9861 20.5447 21.7124 20.5447C21.4303 20.5447 21.1938 20.4534 21.003 20.2709C20.8122 20.0884 20.7168 19.852 20.7168 19.5616C20.7168 19.2712 20.8122 19.0348 21.003 18.8523C21.1938 18.6614 21.4303 18.566 21.7124 18.566C21.9861 18.566 22.2184 18.6614 22.4092 18.8523C22.6084 19.0348 22.7079 19.2712 22.7079 19.5616Z"
        fill="#31d4b6"
      />
      <path
        d="M19.0938 20.3829H17.588V13.8744H19.0938V20.3829ZM19.2556 11.9704C19.2556 12.2525 19.1643 12.4765 18.9818 12.6424C18.7993 12.8084 18.5836 12.8913 18.3347 12.8913C18.0775 12.8913 17.8577 12.8084 17.6751 12.6424C17.4926 12.4765 17.4014 12.2525 17.4014 11.9704C17.4014 11.6801 17.4926 11.4519 17.6751 11.286C17.8577 11.1201 18.0775 11.0371 18.3347 11.0371C18.5836 11.0371 18.7993 11.1201 18.9818 11.286C19.1643 11.4519 19.2556 11.6801 19.2556 11.9704Z"
        fill="white"
      />
      <path
        d="M13.2182 19.325C13.6828 19.325 14.0354 19.3001 14.276 19.2503V17.5827C14.1931 17.5579 14.0728 17.533 13.9151 17.5081C13.7575 17.4832 13.5833 17.4707 13.3925 17.4707C13.2265 17.4707 13.0565 17.4832 12.8822 17.5081C12.7163 17.533 12.5628 17.5786 12.4218 17.645C12.2891 17.7113 12.1812 17.8067 12.0982 17.9312C12.0153 18.0473 11.9738 18.1967 11.9738 18.3792C11.9738 18.7359 12.0858 18.9848 12.3098 19.1259C12.5338 19.2586 12.8366 19.325 13.2182 19.325ZM13.0938 13.7125C13.5916 13.7125 14.0105 13.7747 14.3507 13.8992C14.6908 14.0236 14.9605 14.1979 15.1596 14.4219C15.367 14.6459 15.5122 14.9196 15.5951 15.2432C15.6864 15.5585 15.732 15.9069 15.732 16.2885V20.2334C15.4997 20.2832 15.1471 20.3413 14.6742 20.4076C14.2096 20.4823 13.6828 20.5196 13.0938 20.5196C12.7039 20.5196 12.3471 20.4823 12.0236 20.4076C11.7 20.333 11.4221 20.2127 11.1898 20.0467C10.9658 19.8808 10.7874 19.6651 10.6547 19.3996C10.5302 19.1342 10.468 18.8065 10.468 18.4165C10.468 18.0432 10.5385 17.7279 10.6796 17.4707C10.8289 17.2136 11.028 17.0062 11.2769 16.8485C11.5258 16.6826 11.812 16.5665 12.1356 16.5001C12.4674 16.4254 12.8117 16.3881 13.1685 16.3881C13.3344 16.3881 13.5086 16.4005 13.6911 16.4254C13.8736 16.442 14.0686 16.4752 14.276 16.525V16.2761C14.276 16.1019 14.2553 15.9359 14.2138 15.7783C14.1723 15.6207 14.0976 15.4838 13.9898 15.3676C13.8902 15.2432 13.7534 15.1478 13.5791 15.0814C13.4132 15.015 13.2016 14.9819 12.9445 14.9819C12.596 14.9819 12.2766 15.0067 11.9862 15.0565C11.6959 15.1063 11.4594 15.1644 11.2769 15.2307L11.0902 14.0112C11.2811 13.9448 11.559 13.8784 11.924 13.8121C12.2891 13.7457 12.679 13.7125 13.0938 13.7125Z"
        fill="white"
      />
      <path
        d="M14.8889 3.93869C14.8889 3.32504 15.3863 2.82758 16 2.82758C23.732 2.82758 30 9.09559 30 16.8276C30 24.5596 23.732 30.8276 16 30.8276C8.26801 30.8276 2 24.5596 2 16.8276C2 16.2139 2.49746 15.7165 3.11111 15.7165C3.72476 15.7165 4.22222 16.2139 4.22222 16.8276C4.22222 23.3323 9.49531 28.6054 16 28.6054C22.5047 28.6054 27.7778 23.3323 27.7778 16.8276C27.7778 10.3229 22.5047 5.0498 16 5.0498C15.3863 5.0498 14.8889 4.55234 14.8889 3.93869Z"
        fill={`url(#${id}-gradient)`}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default AiIcon;
