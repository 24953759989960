import { StoryDecision } from '@aily/graphql-sdk/schema';
import { Box, Stack } from '@mui/material';
import { filter, find, isEmpty, isEqual, isNil, slice } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Slideshow,
  SlideshowProps,
  SlideshowProvider,
  useSlideshow,
} from '../../../../components/Slideshow';
import { useDeepCompareMemoize } from '../../../../hooks';
import { AilyAgentOperation } from '../../classes';
import { useAilyAgent } from '../../providers';
import {
  AgentJsonData,
  OptimisationLever,
  Popup,
  Screen,
  ScreenType,
  SliderValueType,
} from '../../types/agentJsonData';
import { getAgentAudioPath } from '../../utils/getAgentAudioPath';
import AgentIntro from '../AgentIntro/AgentIntro';
import { AgentDefaultHeader } from '../AgentScreenItems/AgentDefaultHeader';
import { AgentHeader } from '../AgentScreenItems/AgentHeader';
import { AgentSlideshow } from './AgentSlideshow';
import { AgentSlideshowSlideSectionBuilder } from './AgentSlideshowSlideSectionBuilder';

export const AgentSlideshowSlideContent = ({
  screens,
  onAfterScreen,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
  onSetExtractedLeversScreens,
  switchValue,
  onSetSwitchValue,
  sliderValues,
  onSetSliderValues,
  optimizationScope,
  onSetOptimizationScope,
}: {
  screens: Screen[];
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  baseAudioURL: string;
  agentJsonData: AgentJsonData;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onSetExtractedLeversScreens: (screens: Screen[]) => void;
  switchValue: { value: number };
  onSetSwitchValue: ({ value }: { value: number }) => void;
  sliderValues: SliderValueType;
  onSetSliderValues: (sliderValues: SliderValueType) => void;
  optimizationScope: number;
  onSetOptimizationScope: (value: number) => void;
}) => {
  const { startOperations, exit, setWispSize, wispSize } = useAilyAgent();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const refs = useRef(new Array(screens.length).fill(null));
  const [refsReady, setRefsReady] = useState(false);
  const memoizedScreens = useDeepCompareMemoize(screens);

  const { state } = useSlideshow();
  const { audioEnabled } = state;

  // Function to handle setting of refs
  const setRef = (node: HTMLElement | null, index: number) => {
    refs.current[index] = node;
    // Check if all refs are set
    if (refs.current.every((ref) => ref !== null) && !refsReady) {
      setRefsReady(true); // Set flag to prevent re-triggering
    }
  };

  useEffect(() => {
    if (state.currentGroup === 0 || state.currentGroup === 2) {
      if (wispSize.height !== 700 && wispSize.width !== 700) {
        setWispSize(700, 700);
      }
    }

    if (state.currentGroup === 1) {
      if (wispSize.height !== 186 && wispSize.width !== 186) {
        setWispSize(186, 186);
      }
    }
  }, [state.currentGroup, setWispSize]);

  useEffect(() => {
    if (!refsReady || !audioEnabled) {
      return;
    }
    const operations = refs.current.reduce((acc, ref, index) => {
      const screen = screens[index];
      if (screen?.audio_track) {
        acc.push({
          audioPath: getAgentAudioPath(baseAudioURL, screen.audio_track),
          elementRef: ref,
          delayAfterEnd: 1000,
          corner: 'top-right',
        });
      }

      return acc;
    }, [] as AilyAgentOperation[]);
    if (operations.length) {
      exit();
      startOperations(operations);
    }

    return () => {
      exit();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [refsReady, memoizedScreens, audioEnabled, baseAudioURL, exit, screens, startOperations]);

  return (
    <Stack
      data-testid="agent-slideshow-slide-content"
      direction="row"
      spacing={3}
      justifyContent="center"
      sx={{ width: '100%', height: '520px', marginRight: '60px', marginLeft: '60px' }}
    >
      {memoizedScreens.map((screen, index) => (
        <AgentSlideshowSlideSectionBuilder
          key={index}
          screen={screen}
          titleRef={(node) => setRef(node, index)}
          onAfterScreen={onAfterScreen}
          StackProps={{ paddingLeft: 0 }}
          baseAudioURL={baseAudioURL}
          agentJsonData={agentJsonData}
          decision={decision}
          onAgentClose={onAgentClose}
          onBackdropClose={onBackdropClose}
          onSetExtractedLeversScreens={onSetExtractedLeversScreens}
          switchValue={switchValue}
          onSetSwitchValue={onSetSwitchValue}
          sliderValues={sliderValues}
          onSetSliderValues={onSetSliderValues}
          optimizationScope={optimizationScope}
          onSetOptimizationScope={onSetOptimizationScope}
        />
      ))}
    </Stack>
  );
};

const extractTitleScreen = (screens: Screen[]) => {
  const introIndex = screens.findIndex((screen) => screen.screen_type === ScreenType.Intro);

  if (introIndex !== -1) {
    const defaultIndex = screens
      .slice(introIndex + 1)
      .findIndex((screen) => screen.screen_type === ScreenType.Default);

    if (defaultIndex !== -1) {
      return screens[introIndex + 1 + defaultIndex];
    }
  }

  return null;
};

// this function removes screens that shouldn't be visible
// removes either screens with is_default false
// or returns only visible screens from the decision.screenIds array
const extractVisibleScreens = (decision: StoryDecision | null, screens: Screen[]) => {
  const decisionScreenIds = decision?.screenIds || [];
  const hasDecisionScreens = decisionScreenIds.length > 0;

  // Filter screens by decision IDs
  const filterDecisionScreens = (screens: Screen[]) =>
    screens.filter(({ screen_id }) => decisionScreenIds.includes(screen_id));

  // Filter screens by decision IDs inside Popup
  const filterPopupScreens = (popup?: Popup) => {
    if (!popup) {
      return null;
    }

    return {
      ...popup,
      screens: filterDecisionScreens(popup.screens),
    };
  };

  // Filter screens by decision IDs inside optimisation levers
  const filterLeversScreens = (optimisationLevers?: OptimisationLever[]) =>
    (optimisationLevers || []).flatMap(({ screen_dict }) =>
      Object.values(screen_dict).flatMap(({ screens }) => filterDecisionScreens(screens)),
    );

  const agentScreens = screens
    .flatMap((screen) => {
      const { is_default, screen_id, screen_type, popup, optimisation_levers, ...restScreenData } =
        screen;

      const isScreenIncludedInDecision = decisionScreenIds.includes(screen_id);
      const isLeversScreen = screen_type === ScreenType.Levers;

      if (hasDecisionScreens) {
        const decisionScreens = new Array<Screen>();

        // Include screen if it's part of the decision screens array
        if (isScreenIncludedInDecision) {
          const filteredPopup = filterPopupScreens(popup);

          decisionScreens.push({
            is_default,
            screen_id,
            screen_type,
            ...restScreenData,
            ...(filteredPopup && { popup: filteredPopup }),
          });
        }

        // Filter Optimisation lever screens
        if (isLeversScreen) {
          const leversScreens = filterLeversScreens(optimisation_levers);
          decisionScreens.push(...leversScreens);
        }

        return decisionScreens;
      }

      if (is_default) {
        // If there are no decision screens, include the screen if it is default
        return {
          is_default,
          screen_id,
          screen_type,
          popup,
          optimisation_levers,
          ...restScreenData,
        };
      }
    })
    .filter(Boolean);

  // on web the title is built from a screen, which shouldn't be rendered as a screen afterwards, so we take it out here
  const titleScreenId = extractTitleScreen(screens)?.screen_id;
  const agentScreensWithoutTitle = filter(
    agentScreens,
    (screen) => screen?.screen_id != titleScreenId,
  );

  // extract agent levers screens
  const leversScreen = find(agentScreensWithoutTitle, { screen_type: ScreenType.Levers });

  // if the decision is taken, return screens, otherwise return only decision levers screen
  // if the decision is not taken, but the agent has no decision levers screens, return all screens
  if (decision || (!decision && isEmpty(leversScreen))) {
    // since intro screen is rendered separately, we exclude it from screens map if it exists
    if (agentScreensWithoutTitle[0]?.screen_type === ScreenType.Intro) {
      return slice(agentScreensWithoutTitle, 1) as Screen[];
    }

    return agentScreensWithoutTitle as Screen[];
  }

  return [leversScreen] as Screen[];
};

const extractIntroScreen = (agentScreens: Screen[]) => {
  // return intro screen only if it exists and is_default true
  const introScreen =
    agentScreens[0]?.screen_type === ScreenType.Intro ? agentScreens[0] : ({} as Screen);

  return introScreen.is_default === true ? introScreen : ({} as Screen);
};

const SliderButtons = () => {
  return (
    <>
      <Slideshow.PrevButton />
      <Slideshow.PlayButton />
      <Slideshow.NextButton />
    </>
  );
};

export interface AgentSlideshowBuilderProps extends Omit<SlideshowProps, 'children'> {
  agentJsonData: AgentJsonData;
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  baseAudioURL: string;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
}

export const AgentSlideshowBuilder: React.FC<AgentSlideshowBuilderProps> = React.memo(
  ({
    agentJsonData,
    onAfterScreen,
    baseAudioURL,
    decision,
    onAgentClose,
    onBackdropClose,
    ...rest
  }) => {
    // first run through this algorithm to ensure all screens that shouldn't be visible (nested ones too), are removed
    const agentScreens = extractVisibleScreens(decision, agentJsonData.screens);
    const screensPerSlide = 3;
    // subscreens of selected combination for decision levers
    const [extractedLeversScreens, setExtractedLeversScreens] = useState<Screen[]>([]);
    // below are required state vars to persist once the decision levers gets rerendered
    // in other words, to not be reset to initial values (options on levers)
    const [optimizationScope, setOptimizationScope] = useState(0);
    const [switchValue, setSwitchValue] = useState({ value: 0 });
    const [sliderValues, setSliderValues] = useState<SliderValueType>({});
    const titleScreen = extractTitleScreen(agentJsonData.screens);
    const introScreen = extractIntroScreen(agentJsonData.screens);

    const handleSetSliderValues = useCallback(
      (sliderValues: SliderValueType) => {
        setSliderValues(sliderValues);
      },
      [setSliderValues],
    );

    const handleSetExtractedLeversScreens = useCallback(
      (screens: Screen[]) => {
        setExtractedLeversScreens(screens);
      },
      [setExtractedLeversScreens],
    );

    const screens = useMemo(() => {
      if (decision) {
        return agentScreens;
      }

      return [...agentScreens, ...extractedLeversScreens];
    }, [extractedLeversScreens, agentScreens, decision]);

    const totalSlides = useMemo(() => {
      return Math.ceil(screens.length / screensPerSlide);
    }, [screens]);

    const leversScreen = find(agentScreens, { screen_type: ScreenType.Levers });

    const getPopupButtons = (screens: Screen[]) => {
      return screens.reduce<Popup[]>((result, screen) => {
        if (!decision && leversScreen && screen.popup && screen.popup.button_title) {
          result.push(screen.popup);
        }

        return result;
      }, []);
    };

    const renderHeader = useCallback(() => {
      if (titleScreen) {
        return (
          <AgentDefaultHeader
            onBackdropClose={onBackdropClose}
            onAgentClose={onAgentClose}
            baseAudioURL={baseAudioURL}
            decision={decision}
            agentJsonData={agentJsonData}
            titleScreen={titleScreen}
            headerPopupButtons={getPopupButtons(screens)}
            introGroupExists={!isNil(introScreen)}
          />
        );
      }

      return <AgentHeader title={agentJsonData.headline} subTitle={''} />;
    }, [
      titleScreen,
      agentJsonData,
      baseAudioURL,
      decision,
      onAgentClose,
      onBackdropClose,
      screens,
      introScreen,
    ]);

    const introScreenGroup = useMemo(() => {
      return (
        introScreen && (
          <Slideshow.SlideGroup key={`slide-group-intro`} index={0}>
            <Slideshow.Slide key="slide-intro" index={0}>
              <AgentIntro screen={introScreen} />
            </Slideshow.Slide>
          </Slideshow.SlideGroup>
        )
      );
    }, [introScreen]);

    const slideScreensGroup = useCallback(
      (introScreenExists: boolean) => {
        return (
          <Slideshow.SlideGroup index={introScreenExists ? 1 : 0}>
            {Array.from({ length: totalSlides }, (_, slideIndex) => {
              const start = slideIndex * screensPerSlide;
              const end = start + screensPerSlide;
              const currentScreens = screens.slice(start, end);
              return (
                <Slideshow.Slide key={`slide-${slideIndex}`} index={slideIndex}>
                  <AgentSlideshowSlideContent
                    screens={currentScreens}
                    onAfterScreen={onAfterScreen}
                    baseAudioURL={baseAudioURL}
                    agentJsonData={agentJsonData}
                    decision={decision}
                    onAgentClose={onAgentClose}
                    onBackdropClose={onBackdropClose}
                    onSetExtractedLeversScreens={handleSetExtractedLeversScreens}
                    switchValue={switchValue}
                    onSetSwitchValue={setSwitchValue}
                    sliderValues={sliderValues}
                    onSetSliderValues={handleSetSliderValues}
                    optimizationScope={optimizationScope}
                    onSetOptimizationScope={setOptimizationScope}
                  />
                </Slideshow.Slide>
              );
            })}
          </Slideshow.SlideGroup>
        );
      },
      [
        totalSlides,
        screensPerSlide,
        screens,
        onAfterScreen,
        baseAudioURL,
        agentJsonData,
        decision,
        onAgentClose,
        onBackdropClose,
        handleSetExtractedLeversScreens,
        switchValue,
        setSwitchValue,
        sliderValues,
        handleSetSliderValues,
        optimizationScope,
        setOptimizationScope,
      ],
    );

    return (
      <SlideshowProvider>
        <Box position="relative" data-testid="slideshow-builder-container">
          <AgentSlideshow data-testid="slideshow-container" {...rest}>
            <Slideshow.Screen index={0} header={renderHeader()}>
              {introScreenGroup}
              {/* if there is an intro screen, start indexing other groups from 1 as 0 is reserved */}
              {slideScreensGroup(!isNil(introScreen))}
            </Slideshow.Screen>
          </AgentSlideshow>
        </Box>
        <SliderButtons />
      </SlideshowProvider>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
