import { useEffect } from 'react';
import { isSafari } from 'react-device-detect';

export function useSafariOverflow() {
  useEffect(() => {
    if (isSafari) {
      window.document.documentElement.style.setProperty('overflow', 'scroll');
    }

    return () => {
      if (isSafari) {
        window.document.documentElement.style.removeProperty('overflow');
      }
    };
  }, []);
}
