import {
  EventTrackingConfig,
  getInsightAssetName,
  getPathDetails,
  withEventTracking,
} from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import { styled, Typography, TypographyProps } from '@mui/material';
import { find, kebabCase, toString } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AgentModal, AgentRecommenderItem } from '../../features/Agent';
import { useHandleLink, useSearchParamState } from '../../hooks';
import { ArrowLongRight } from '../../icons';
import { RealModal } from '../RealModal/RealModal';
import { RecommenderItemProps, RecommenderItemSkeleton } from '../Recommender';
import StoryCoverImage from '../Recommender/StoryCoverImage';
import StoryIcon from '../Recommender/StoryIcon';
import StorySliderModal from '../StorySlider/StorySliderModal';
import { RecommenderList } from './RecommenderList';
import { getStoryGradient, getTriangularCornerProps } from './utils';
interface RecommenderDataViewProps {
  dataView?: T.RecommenderDataView;
  loading?: boolean;
  onBackdropClose?: (forceRefetch: boolean) => void;
  onItemClick?: (story: T.Story, index: number, url?: string) => void;
  onSeeAllLinkClick?: (url: string) => void;
}

const SeeAllLink = styled(({ children = 'See all', ...rest }: TypographyProps) => (
  <Typography variant="small" data-testid="SeeAllLink" {...rest}>
    {children}
    <ArrowLongRight />
  </Typography>
))(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.text.primary,
  },
  '& .MuiSvgIcon-root': { marginLeft: theme.spacing(0.75) },
}));

const maxItems = 6;

const RecommenderDataView: React.FC<RecommenderDataViewProps> = ({
  dataView,
  loading,
  onBackdropClose,
  onItemClick,
  onSeeAllLinkClick,
}) => {
  const [forceRefetch, setForceRefetch] = useState(false);
  const { pathname } = useLocation();
  const handleLink = useHandleLink();

  const [storyId, setStoryId] = useSearchParamState<string | undefined>({
    paramName: 'storyId',
    serialize: toString,
    deserialize: toString,
  });

  const recommenderStories = useMemo(
    () => dataView?.insights?.slice(0, maxItems),
    [dataView?.insights],
  );

  const stories = useMemo(
    () =>
      (recommenderStories as T.Story[])?.filter(
        (story) =>
          story?.insightsType !== T.InsightsType.Real &&
          story?.storyType !== T.StoryDisplayType.Real,
      ),
    [recommenderStories],
  );

  const defaultSelectedIndex = useMemo(() => {
    if (!stories?.length || !storyId) {
      return undefined;
    }

    const storyIndex = stories.findIndex((story) => story?.id === storyId);
    return storyIndex !== -1 ? storyIndex : 0;
  }, [stories, storyId]);

  const handleListItemClick = useCallback(
    (story: T.Story, index: number, path?: string) => () => {
      setStoryId(story.id, undefined, undefined, path ?? 'recommender');
      onItemClick?.(story, index, pathname);
    },
    [onItemClick],
  );

  const handleClose = useCallback(() => {
    setStoryId(undefined, undefined, undefined, '.');
    onBackdropClose?.(forceRefetch);
  }, [onBackdropClose, forceRefetch]);

  const handleStorySliderChange = useCallback(
    (story: T.Story) => {
      if (story?.id) {
        setStoryId(story.id);
      }
    },
    [setStoryId],
  );

  const handleStoryRead = useCallback(() => {
    setForceRefetch(true);
  }, []);

  const handleSeeAllLinkClick = useCallback(() => {
    if (dataView?.seeAllLink) {
      handleLink(dataView.seeAllLink);
      onSeeAllLinkClick?.(dataView.seeAllLink?.absolutePath || '');
    }
  }, [dataView, handleLink, onSeeAllLinkClick]);

  const toolbarAddons = useMemo(
    () => (dataView?.seeAllLink ? <SeeAllLink onClick={handleSeeAllLinkClick} /> : null),
    [dataView?.seeAllLink, handleSeeAllLinkClick],
  );

  if (loading) {
    return (
      <RecommenderList>
        {Array.from({ length: 6 }, (_, index) => (
          <RecommenderItemSkeleton key={index} index={index} />
        ))}
      </RecommenderList>
    );
  }

  return (
    <>
      <RecommenderList toolbarAddons={toolbarAddons}>
        {(recommenderStories as T.Story[])?.map((story, index) => {
          const itemProps: RecommenderItemProps & { [key: `data-${string}`]: unknown } = {
            title:
              story.recommenderHeadline || story.headline || (story as T.Real & T.Story)?.title,
            icon: <StoryIcon story={story} />,
            onClick: handleListItemClick(
              story,
              index,
              story.insightsType === T.InsightsType.Real ? 'real' : 'recommender',
            ),
            checked: story.isRead,
            coverImage: <StoryCoverImage story={story} />,
            'data-x-story-type': kebabCase(story.storyType),
            'data-x-sentiment': story.sentiment ? kebabCase(story.sentiment) : undefined,
            BackgroundBoxProps: { sx: { background: getStoryGradient(story), opacity: 0.2 } },
            TriangularCornerProps: getTriangularCornerProps(story),
          };

          // render agent
          if (story.storyType === T.StoryDisplayType.Real) {
            return (
              <AgentRecommenderItem
                key={story.id}
                story={story}
                isWideCard={false}
                onPress={handleListItemClick(story, index, 'agent')}
              />
            );
          }

          return (
            <RecommenderList.Item
              key={story.id}
              {...itemProps}
              isWrappedReal={(story as T.Story & T.Real)?.realType === T.RealType.Wrapped}
            />
          );
        })}
      </RecommenderList>
      <RealModal
        open={pathname.endsWith('real')}
        data={find(recommenderStories as T.Real[], { id: storyId })}
        onClose={handleClose}
      />
      {pathname.endsWith('agent') && (
        <AgentModal
          open={pathname.endsWith('agent')}
          parent={dataView?.id}
          story={find(recommenderStories as T.Story[], { id: storyId })}
          onClose={handleClose}
        />
      )}
      <StorySliderModal
        open={pathname.endsWith('recommender')}
        stories={stories as T.Story[]}
        defaultSlide={defaultSelectedIndex}
        onClose={handleClose}
        onCurrentSlideChange={handleStorySliderChange}
        onStoryRead={handleStoryRead}
      />
    </>
  );
};

const trackingConfig: EventTrackingConfig<RecommenderDataViewProps> = {
  onItemClick: {
    eventName: 'recommender.opened',
    getEventProps: (story, index, path) => {
      const { focusArea, focusAreaPath } = getPathDetails(story.link?.absolutePath ?? '');
      return {
        component: 'recommender',
        name: story.headline,
        component_id: story.id,
        component_type: story.storyType,
        intent: 'open',
        item_type: 'card',
        event_version: '2.0.0',
        url: path ?? '',
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
        component_value: getInsightAssetName(story),
      };
    },
  },
  onSeeAllLinkClick: {
    eventName: 'recommender.clicked',
    getEventProps: (url) => ({
      component: 'recommender',
      component_value: 'see_all',
      intent: 'click',
      item_type: 'link',
      event_version: '2.0.0',
      url,
    }),
  },
};

export const TrackedRecommenderDataView = withEventTracking(RecommenderDataView, trackingConfig);

export default RecommenderDataView;
