import {
  GetDataViewDocument,
  GetDataViewQueryVariables,
  GetFiltersByIdDocument,
  GetFiltersByIdQuery,
  GetFiltersByIdQueryVariables,
} from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { useLazyQuery } from '@aily/saas-graphql-client';
import { TypedDocumentNode } from '@apollo/client';
import type { OperationVariables } from '@apollo/client/core';
import { Alert, Box, styled } from '@mui/material';
import { Series } from 'highcharts';
import { isEqual, isNil, sortBy, unionBy } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { usePrevious } from 'react-use';

import H from '../../config/highchartsConfig';
import { useFiltersContext } from '../../contexts';
import { useDrillId, useFilters, useLink } from '../../hooks';
import { useModule } from '../../providers';
import {
  createSelectFilterValue,
  getFilterIdFromFilterInput,
  mapFilterComponentToFilterValue,
  mapFilterValueToFilterInput,
  reduceFilterValueToDependentFilterValue,
  reduceFilterValueToFilterInput,
} from '../../utils';
import { DataViewFooterToolbar } from '../DataViewFooterToolbar';
import {
  FilterToolbar,
  FilterToolbarProps,
  useHandleFilterAlign,
  useHandleFilterDisplayMode,
} from '../FilterToolbar';
import { LoadingBackdrop } from '../LoadingBackdrop';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { WaterfallChart } from '../WaterfallChart';
import { Chart } from './Chart';
import { ChartDataViewAbstractType } from './types';

export type ChartQueryAbstractType = { __typename?: 'Query'; dataView?: ChartDataViewAbstractType };
export type ChartComponentAbstractType = T.Component & {
  filters?: T.FilterComponent[] | null;
  drillIds?: number[] | null;
};

export interface ChartDataViewProps<
  TData extends ChartQueryAbstractType = ChartQueryAbstractType,
  TVariables extends OperationVariables = OperationVariables,
> {
  component: ChartComponentAbstractType;
  defaultChartOptions?: H.Options;
  delay?: number;
  query?: TypedDocumentNode<TData, TVariables>;
  getDataView?: (data: TData) => ChartDataViewAbstractType;
  customOptions?: Record<string, unknown>;
  additionalInput?: T.InputMaybe<Omit<T.DataViewInput, 'id' | 'moduleId'>>;
  customInfoFooter?: (metadata: T.MetaData[]) => React.ReactNode;
  showFormattedValueInLabel?: (series: Series) => boolean;
}

const ContainerBox = styled(Box)({
  position: 'relative',
});

const ChartContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'loading',
})<{ loading?: boolean }>(({ loading }) => ({
  ...(loading && {
    opacity: 0.3,
  }),
}));

export const ChartDataView = <
  TData extends ChartQueryAbstractType = ChartQueryAbstractType,
  TVariables extends OperationVariables = OperationVariables,
>({
  component,
  defaultChartOptions,
  delay,
  query = GetDataViewDocument,
  getDataView,
  customOptions,
  additionalInput,
  customInfoFooter,
  showFormattedValueInLabel,
}: ChartDataViewProps<TData, TVariables>) => {
  const {
    id: componentId,
    code: componentCode,
    drillIds: defaultDrillIds,
    filters: filterComponents,
  } = component;

  const link = useLink();
  const moduleId = useModule()?.id ?? '';
  const moduleCode = useModule()?.moduleCode ?? '';

  const drillIds = useDrillId() ?? defaultDrillIds;
  const filtersContext = useFiltersContext();

  const visibleFilterIds = useMemo(
    () => filterComponents?.filter(({ isHidden }) => !isHidden)?.map(({ id }) => id) ?? [],
    [filterComponents],
  );

  const defaultFilterValues = useMemo(
    () =>
      filterComponents
        ? reduceFilterValueToDependentFilterValue(
            unionBy(
              filtersContext.filterValues,
              link?.filters,
              filterComponents?.map(mapFilterComponentToFilterValue),
              'id',
            ),
            filterComponents,
          )
        : undefined,
    [filterComponents, filtersContext.filterValues, link?.filters],
  );

  const defaultFilterInputs = useMemo(
    () => defaultFilterValues?.reduce(reduceFilterValueToFilterInput, []),
    [defaultFilterValues],
  );

  const [dataView, setDataView] = useState<ChartDataViewAbstractType>();
  const getDataViewRef = useRef(getDataView);
  const [loading, setLoading] = useState(true);
  const [visibleFilters, setVisibleFilters] = useState<T.SelectFilter[]>([]);
  const [filterInputs, setFilterInputs] = useState(defaultFilterInputs);
  const [userSettingsInput, setUserSettingsInput] = useState<T.UserSettingInput[]>([]);

  const [isDelayed, setIsDelayed] = useState(!!delay);
  const [isValidFilter, setIsValidFilter] = useState(true);

  const previousIsValidFilter = usePrevious(isValidFilter);

  useEffect(() => {
    if (!delay) return;

    const timer = setTimeout(() => {
      setIsDelayed(false);
    }, delay);

    // Cleanup function to clear the timer on unmount
    return () => clearTimeout(timer);
  }, [delay]);

  const handleCompleted = useCallback((data: TData) => {
    const dataView = getDataViewRef.current ? getDataViewRef.current(data) : data.dataView;
    setDataView(dataView);
    setLoading(false);
  }, []);

  const handleError = useCallback(() => {
    setLoading(false);
  }, []);

  const [fetchData, { error }] = useLazyQuery<TData>(query, {
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const getFilters = useCallback(
    ({ filtersById }: GetFiltersByIdQuery) => (filtersById ?? []) as T.Filter[],
    [],
  );

  const {
    filters,
    loading: filtersLoading,
    filterValues,
    setFilterValues,
  } = useFilters<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>({
    query: GetFiltersByIdDocument,
    variables: {
      ids: visibleFilterIds,
      moduleId,
      filters: filterInputs,
      drillIds,
    },
    getFilters,
    defaultFilterValues,
    skip: !visibleFilterIds.length,
  });

  const filteredArray = filterInputs?.filter((mockItem) =>
    filters.some((filterItem) => filterItem.id === mockItem?.selectFilter?.id),
  );

  const isValid = filteredArray?.every(({ selectFilter }) => {
    const matchingFilter = filters.find((filter) => filter.id === selectFilter?.id);

    if (T.isSelectFilter(matchingFilter) && matchingFilter.options) {
      return matchingFilter.options.some((option) => option.value === selectFilter?.value);
    }
  });

  useEffect(() => {
    if (!previousIsValidFilter) setFilterInputs(defaultFilterInputs);
  }, [previousIsValidFilter]);

  useUpdateEffect(() => {
    if (filterComponents) {
      const newFilterValues = reduceFilterValueToDependentFilterValue(
        filtersContext.filterValues,
        filterComponents,
      );

      setFilterValues((prevState) => {
        const newState = unionBy(newFilterValues, prevState, 'id');

        if (!isEqual(sortBy(newState, 'id'), sortBy(prevState, 'id'))) {
          return newState;
        }

        return prevState;
      });

      setFilterInputs((prevState) => {
        const newState = newFilterValues.reduce(reduceFilterValueToFilterInput, []);

        if (
          !isEqual(
            sortBy(newState, getFilterIdFromFilterInput),
            sortBy(prevState, getFilterIdFromFilterInput),
          )
        ) {
          return newState;
        }

        return prevState;
      });
    }
  }, [filterComponents, filtersContext.filterValues]);

  useEffect(() => {
    // Wait for the data to load before loading new filters, otherwise a non-existent filter option may be selected
    if (!dataView || (!loading && !filtersLoading)) {
      setVisibleFilters(filters as T.SelectFilter[]);
    }
  }, [dataView, loading, filtersLoading, filters]);

  const variables = useMemo(() => {
    return {
      input: {
        id: componentId,
        moduleId,
        drillIds,
        filters: filterInputs,
        userSettings: userSettingsInput,
        componentCode,
        ...(additionalInput || {}),
      },
    } satisfies GetDataViewQueryVariables;
  }, [
    componentId,
    moduleId,
    drillIds,
    filterInputs,
    userSettingsInput,
    componentCode,
    moduleCode,
    additionalInput,
  ]);

  const doFetchData = useCallback(() => {
    setLoading(true);
    fetchData({ variables });
  }, [variables]);

  useEffect(doFetchData, [doFetchData]);

  const handleFilterChange: NonNullable<FilterToolbarProps['onFilterChange']> = useCallback(
    (id, { value }) => {
      const filterValue = createSelectFilterValue(id, value ?? -1);
      const filterInput = mapFilterValueToFilterInput(filterValue) as T.FilterInput;
      setFilterValues((prevState) => unionBy([filterValue], prevState, 'id'));
      setFilterInputs((prevState) => unionBy([filterInput], prevState, getFilterIdFromFilterInput));
      if (!isNil(isValid)) setIsValidFilter(isValid);
    },
    [isValid],
  );

  const handleFilterAlign = useHandleFilterAlign(filterComponents);
  const handleFilterDisplayMode = useHandleFilterDisplayMode(filterComponents);

  const handleSettingChangeCommitted = useCallback(({ key, userValue }: T.UserSetting) => {
    const userSettingInput: T.UserSettingInput = { key, value: userValue as number };
    setUserSettingsInput((prevState) => unionBy([userSettingInput], prevState, 'key'));
  }, []);

  const ChartComponent = useMemo(() => {
    const waterfallSeries = dataView?.series?.find(
      ({ seriesType }) => seriesType === T.SeriesType.Waterfall,
    );

    if (waterfallSeries && T.isChartAxisCategoryViewResult(dataView?.xAxis)) {
      return (
        <WaterfallChart
          categories={dataView?.xAxis.categories as string[]}
          data={
            waterfallSeries.data?.map(({ y }) => ({
              y: y?.value?.raw ?? 0,
            })) as H.PointOptionsObject[]
          }
        />
      );
    }

    return (
      <Chart
        dataView={dataView}
        defaultChartOptions={defaultChartOptions}
        customOptions={customOptions}
        showFormattedValueInLabel={showFormattedValueInLabel}
      />
    );
  }, [dataView, defaultChartOptions]);

  const footer = useMemo(() => {
    if (!dataView) {
      return null;
    }

    if (customInfoFooter && dataView.metadata?.length) {
      return customInfoFooter(dataView.metadata);
    }

    return (
      <DataViewFooterToolbar
        dataView={dataView}
        onUserSettingChangeCommitted={handleSettingChangeCommitted}
      />
    );
  }, [dataView, customInfoFooter]);

  if ((loading && !dataView) || isDelayed) {
    return <LoadingSkeleton />;
  }

  return (
    <ContainerBox>
      <ChartContainer loading={loading} data-testid="chart-container">
        {!!error && (
          <Alert variant="outlined" severity="error">
            {error.graphQLErrors[0]?.message ?? error.message}
          </Alert>
        )}
        {!!visibleFilterIds.length && (
          <FilterToolbar
            filters={visibleFilters}
            selectedFilterOptions={filterValues}
            onFilterChange={handleFilterChange}
            onFilterAlign={handleFilterAlign}
            onFilterDisplayMode={handleFilterDisplayMode}
          />
        )}
        {ChartComponent}
        {footer}
      </ChartContainer>
      {loading && <LoadingBackdrop sx={{ backgroundColor: 'transparent' }} />}
    </ContainerBox>
  );
};
