import { AilyLogo, Heading, HStack, MaskedText, VStack } from '@aily-labs/ui';
import React from 'react';

import HardCoded from './assets/wrapped_real_last_screen_image.png';

const maskedTextProps = {
  fontSize: 'fontSize36',
  fontWeight: 'fontWeightBold',
  gradient: 'mint-chart',
} as const;

export const TempHardCodedLastScreen = () => {
  return (
    <VStack justifyContent="center" gap={20} overflow="hidden" flexShrink={1} alignItems="center">
      <VStack justifyContent="center" alignItems="center">
        <MaskedText {...maskedTextProps} text={'Happy'} />
        <MaskedText {...maskedTextProps} text={'Holidays'} />
      </VStack>
      <img src={HardCoded} width={230} height={230} />
      <VStack justifyContent="center" alignItems="center" gap={'$spacingXXSmall'} flexShrink={1}>
        <MaskedText {...maskedTextProps} text={'from'} />
        <HStack gap={'$spacingSmall'} justifyContent="center" alignItems="center" flexShrink={1}>
          <AilyLogo width={60} />
          <Heading
            fontSize={36}
            lineHeight={42.2}
            textAlign="center"
            fontWeight="bold"
            color="$white"
            fontFamily="$fontFamilyHeading"
          >
            team
          </Heading>
        </HStack>
      </VStack>
    </VStack>
  );
};
